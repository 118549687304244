import * as React from 'react';
import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { setUserData } from 'Client/common/actions/userActions';
import CreateCloudProjectIaasCloudServersConfiguration from './CreateCloudProjectIaasCloudServersConfiguration__JsBridge.res.js';

const mapDispatchToProps = dispatch => ({
  reduxfunction: {
    setUserData: userData => {
      dispatch(setUserData(userData));
    },
  },
});

const CreateCloudProjectIaasCloudServersConfigurationContainer = connect(
  null,
  mapDispatchToProps,
)(CreateCloudProjectIaasCloudServersConfiguration);

export default ({ context, ...props }) => (
  <ReduxProvider>
    <CreateCloudProjectIaasCloudServersConfigurationContainer
      projectId={props.projectId}
      configurations={
        // FIXME: [IaaS Servers] serverInstances: make singular after BE fix
        props.cloudIaasServersConfigurations.map(cfg => ({
          ...cfg,
          serverInstance: cfg.serverInstances,
        }))
      }
      options={props.options}
      railsContext={context}
      status={props.status}
      reduxfunction={props.reduxfunction}
    />
  </ReduxProvider>
);
