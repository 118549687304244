// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as ContractLength from "../../../../models/ContractLength.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              billingTypes: field.required("billingTypes", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              certifications: field.required("certifications", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              contractLengths: field.required("contractLengths", Json_Decode$JsonCombinators.array(ContractLength.decoder)),
              databases: field.required("databases", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              diskSizes: field.required("diskSizes", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              diskTypes: field.required("diskTypes", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              features: field.required("features", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              hypervisors: field.required("hypervisors", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              images: field.required("images", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              instanceAvailability: field.required("instanceAvailability", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              instanceTypes: field.required("instanceTypes", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              instanceVisibility: field.required("instanceVisibility", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              operatingSystems: field.required("operatingSystems", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              ramSizes: field.required("ramSizes", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              regions: field.required("regions", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              throughputUnits: field.required("throughputUnits", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var Options = {
  decoder: decoder,
  fromJson: fromJson
};

export {
  Options ,
}
/* decoder Not a pure module */
